import React from "react";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
const Privacy = () => {
  const [privacy, setprivacy] = useState([]);
  // Fetch categories
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_KEY}privacy_policy`)
      .then((res) => {
        if (res.data.result === "true") {
          setprivacy(res.data.data); // Adjusted to use correct data path
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  return (
    <>
      <div className="boxed_wrapper ltr">
        <section className="breadcrumb-area">
          <div
            className="breadcrumb-area-bg"
            style={{
              backgroundImage:
                "url(catelogo/ambulance-rescue-emergency-blue-light.jpg)",
            }}></div>
          <div className="container">
            <div className="row">
              <div className="col-xl-12">
                <div className="inner-content">
                  <h2 className="text-white">Privacy Policy</h2>
                  <div className="breadcrumb-menu">
                    <ul>
                      <li>
                        <Link to="/">Home</Link>
                      </li>
                      <li className="active">Privacy Policy</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {privacy.map((item, idx) => (
          <section className="contact-info-style2-area">
            <div className="container">
              <div className="row text-right-rtl">
                <div className="mt-5">
                  <div className="contact-info-style1__content">
                    <div className="sec-title">
                      <h4>{item?.title}</h4>
                      <div className="text">
                        <p
                          dangerouslySetInnerHTML={{
                            __html: item?.description,
                          }}
                          
                        />{" "}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        ))}
      </div>
    </>
  );
};

export default Privacy;
