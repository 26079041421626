import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import axios from "axios";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";

const SignUp = () => {
  const navigate = useNavigate();
  const [form, setForm] = useState({});
  const [categories, setCategories] = useState([]);
  let [loader, setloader] = useState(false);
  const [address, setAddress] = useState("");
  const [details, setDetails] = useState(null);
 // console.log("address", address);
  //console.log("details", details);
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_KEY}get_category`)
      .then((result) => setCategories(result.data.data))
      .catch((err) => console.error(err));
  }, []);
  //console.log("form", form);

  const handleSelect = async (value) => {
    try {
      const results = await geocodeByAddress(value);
      const latLng = await getLatLng(results[0]);
      setAddress(value);
      setDetails(latLng);
    } catch (error) {}
  };

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    setForm((prev) => ({
      ...prev,
      [name]: files ? files[0] : value,
    }));
  };

  const submitForm = (e) => {
    e.preventDefault();

    const formData = new FormData();

    formData.append("service_id", form.service_id);
    formData.append("amb_no", form.amb_no);
    formData.append("amb_owner", form.amb_owner);
    formData.append("email", form.email);
    formData.append("amb_contact_no", form.amb_contact_no);
    formData.append("driving_license", form.driving_license);
    formData.append("experience", form.experience);
    formData.append("driver_name", form.driver_name);
    formData.append("driver_email", form.driver_email);
    formData.append("country_code", form.country_code);
    formData.append("driver_mobile", form.driver_mobile);
    formData.append("password", form.password);
    formData.append("vehicle_number", form.vehicle_number);
    formData.append("lat", details?.lat || "");
    formData.append("lng", details?.lng || "");
    formData.append("location", address);

    // Append file fields
    if (form.ambu_owner_img)
      formData.append("ambu_owner_img", form.ambu_owner_img);
    if (form.driving_license_img)
      formData.append("driving_license_img", form.driving_license_img);
    if (form.vehicle_img) formData.append("vehicle_img", form.vehicle_img);
    if (form.passport_image)
      formData.append("passport_image", form.passport_image);
    if (form.id_proof_image)
      formData.append("id_proof_image", form.id_proof_image);
    setloader(true);
    axios
      .post(
        `${process.env.REACT_APP_API_KEY}ambulance_registration`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((res) => {
        if (res?.data?.result === "true") {
          setloader(false);
          toast.success(res?.data?.msg);
          setTimeout(() => {
            navigate("/userlogin");
          }, 1000);
        } else {
          toast.error(res?.data?.msg || "Something went wrong");
          setloader(false);
        }
      })
      .catch((err) => {
        console.error(err);
        toast.error("Registration failed!");
        setloader(false);
      });
  };

  return (
    <>
      <Toaster />
      <section className="contact-info-style1-area mt-5">
        <div className="container pt-5">
          <div className="row justify-content-center">
            <div className="col-xl-9 col-lg-9">
              <div className="form-style1-box">
                <div className="top-title text-center">
                  <h3>User-SignUp</h3>
                </div>
                <form
                  onSubmit={submitForm}
                  encType="multipart/form-data"
                  className="col-sm-12">
                  <div className="row justify-content-center">
                    <div className="col-sm-6 form-group">
                      <label>Service Type</label>
                      <select
                        required
                        className="form-control"
                        name="service_id"
                        onChange={handleChange}>
                        <option value="">Select Service Type</option>
                        {categories.map((cat, idx) => (
                          <option key={idx} value={cat.id}>
                            {cat.service_type}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="col-sm-6 form-group">
                      <label>Ambulance Number</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Ambulance Number"
                        name="amb_no"
                        onChange={handleChange}
                        required
                      />
                    </div>

                    <div className="col-sm-6 form-group">
                      <label>Ambulance Owner Name</label>
                      <input
                        type="text"
                        className="form-control"
                        onChange={handleChange}
                        placeholder="Enter Ambulance Owner Name"
                        style={{
                          "-webkit-text-transform": "capitalize",
                          "text-transform": "capitalize",
                        }}
                        name="amb_owner"
                        required
                      />
                    </div>
                    <div className="col-sm-6 form-group">
                      <label>Email</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Email"
                        onChange={handleChange}
                        style={{
                          "-webkit-text-transform": "capitalize",
                          "text-transform": "capitalize",
                        }}
                        name="email"
                        required
                      />
                    </div>
                    <div className="col-sm-6 form-group">
                      <label>Ambulance Contact Number</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Ambulance Contact Number"
                        onChange={handleChange}
                        style={{
                          "-webkit-text-transform": "capitalize",
                          "text-transform": "capitalize",
                        }}
                        name="amb_contact_no"
                        required
                      />
                    </div>
                    <div className="col-sm-6 form-group">
                      <label>Driving License Number</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Driving License Number"
                        onChange={handleChange}
                        style={{
                          "-webkit-text-transform": "capitalize",
                          "text-transform": "capitalize",
                        }}
                        name="driving_license"
                        required
                      />
                    </div>
                    <div className="col-sm-6 form-group">
                      <label>Experience</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Experience"
                        onChange={handleChange}
                        style={{
                          "-webkit-text-transform": "capitalize",
                          "text-transform": "capitalize",
                        }}
                        name="experience"
                        required
                      />
                    </div>
                    <div className="col-sm-6 form-group">
                      <label>Driver Name</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Driver Name"
                        onChange={handleChange}
                        style={{
                          "-webkit-text-transform": "capitalize",
                          "text-transform": "capitalize",
                        }}
                        name="driver_name"
                        required
                      />
                    </div>
                    <div className="col-sm-6 form-group">
                      <label>Driver Email</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Driver Email"
                        onChange={handleChange}
                        style={{
                          "-webkit-text-transform": "capitalize",
                          "text-transform": "capitalize",
                        }}
                        name="driver_email"
                        required
                      />
                    </div>
                    <div className="col-sm-6 form-group">
                      <label>Country Code</label>
                      <select
                        className="form-control"
                        name="country_code"
                        onChange={handleChange}
                        required>
                        <option value={91}>+91 (India)</option>
                        <option value={1}>+1 (USA)</option>
                        <option value={44}>+44 (UK)</option>
                        <option value={93}>+93 (AFG)</option>
                        <option value={355}>+355 (ALB)</option>
                      </select>
                    </div>
                    <div className="col-sm-6 form-group">
                      <label>Driver Mobile</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Driver Mobile"
                        onChange={handleChange}
                        style={{
                          "-webkit-text-transform": "capitalize",
                          "text-transform": "capitalize",
                        }}
                        name="driver_mobile"
                        required
                      />
                    </div>
                    <div className="col-sm-6 form-group">
                      <label>Driver Password</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Driver Password"
                        onChange={handleChange}
                        style={{
                          "-webkit-text-transform": "capitalize",
                          "text-transform": "capitalize",
                        }}
                        name="password"
                        required
                      />
                    </div>
                    <div className="col-sm-6 form-group">
                      <label>Vehicle Number</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Vehicle Number"
                        onChange={handleChange}
                        style={{
                          "-webkit-text-transform": "capitalize",
                          "text-transform": "capitalize",
                        }}
                        name="vehicle_number"
                        required
                      />
                    </div>
                    <div className="form-group col-sm-6">
                      <label>Ambulance Owner Image</label>
                      <input
                        type="file"
                        className="form-control"
                        name="ambu_owner_img"
                        accept="image/*"
                        onChange={handleChange}
                        required
                      />
                      <div style={{ "margin-top": "10px" }}>
                        <img
                          id="imagePreview"
                          src="#"
                          alt="Ambu Owner Image Preview"
                          style={{
                            display: "none",
                            "max-width": "100px",
                            "max-height": "100px",
                          }}
                        />
                      </div>
                    </div>
                    <div className="form-group col-sm-6">
                      <label>Driving License Image</label>
                      <input
                        type="file"
                        className="form-control"
                        name="driving_license_img"
                        onChange={handleChange}
                        accept="image/*"
                        required
                      />
                      <div style={{ "margin-top": "10px" }}>
                        <img
                          id="imagePreview_driver"
                          src="#"
                          alt="Driving License Image Preview"
                          style={{
                            display: "none",
                            "max-width": "100px",
                            "max-height": "100px",
                          }}
                        />
                      </div>
                    </div>
                    <div className="form-group col-sm-6">
                      <label>Vehicle Image</label>
                      <input
                        type="file"
                        className="form-control"
                        name="vehicle_img"
                        accept="image/*"
                        onChange={handleChange}
                        required
                      />
                      <div style={{ "margin-top": "10px" }}>
                        <img
                          id="imagePreview_Vehicle"
                          src="#"
                          alt="Vehicle Image Preview"
                          style={{
                            display: "none",
                            "max-width": "100px",
                            "max-height": "100px",
                          }}
                        />
                      </div>
                    </div>
                    <div className="form-group col-sm-6">
                      <label>Passport Image</label>
                      <input
                        type="file"
                        className="form-control"
                        name="passport_image"
                        accept="image/*"
                        onChange={handleChange}
                        required
                      />
                      <div style={{ "margin-top": "10px" }}>
                        <img
                          id="imagePreview_passport"
                          src="#"
                          alt="Passport Image Preview"
                          style={{
                            display: "none",
                            "max-width": "100px",
                            "max-height": "100px",
                          }}
                        />
                      </div>
                    </div>
                    <div className="form-group col-sm-6">
                      <label>ID Proof Image</label>
                      <input
                        type="file"
                        className="form-control"
                        name="id_proof_image"
                        accept="image/*"
                        onChange={handleChange}
                        required
                      />
                      <div style={{ "margin-top": "10px" }}>
                        <img
                          id="imagePreview_idproof"
                          src="#"
                          alt="ID Proof Image Preview"
                          style={{
                            display: "none",
                            "max-width": "100px",
                            "max-height": "100px",
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-sm-12 form-group">
                      <PlacesAutocomplete
                        value={address}
                        onChange={(newValue) => setAddress(newValue)}
                        onSelect={handleSelect}>
                        {({
                          getInputProps,
                          suggestions,
                          getSuggestionItemProps,
                          loading,
                        }) => (
                          <div className="form-group">
                            <label className="">Address *</label>
                            <input
                              required
                              className="form-control pac-target-input"
                              {...getInputProps({
                                placeholder: "search for a city.",
                              })}
                            />
                            <div>
                              {loading && <div>Loading...</div>}
                              {suggestions?.map((suggestion) => {
                                const style = {
                                  cursor: "pointer",
                                  backgroundColor: suggestion.active
                                    ? "#41b6e6"
                                    : "#fff",
                                };
                                return (
                                  <div
                                    {...getSuggestionItemProps(suggestion, {
                                      style,
                                    })}>
                                    {suggestion?.description}
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        )}
                      </PlacesAutocomplete>
                    </div>
                    {loader == true ? (
                      <div
                        class="spinner-border text-danger"
                        role="status"
                        style={{ width: "3rem", height: "3rem" }}>
                        <span class="sr-only"></span>
                      </div>
                    ) : (
                      <div className="col-sm-12 text-center">
                        <button type="submit" className="btn btn-success mr-2">
                          Submit
                        </button>
                        <button type="reset" className="btn btn-warning">
                          Reset
                        </button>
                      </div>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SignUp;
