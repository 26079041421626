import React, { useEffect, useRef, useState } from "react";
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import { InputText } from "primereact/inputtext";
import { Skeleton } from "primereact/skeleton";
import axios from "axios";
import swal from "sweetalert";
import toast, { Toaster } from "react-hot-toast";

const Category = () => {
  let [activeItem, setActiveItem] = useState(null);
  let [categorydata, setcategorydata] = useState([]);
  let [loader, setloader] = useState(false);
  let [loaderbtn, setloaderbtn] = useState(false);
  let [cate_id, setcate_id] = useState("");
  const [visible, setVisible] = useState(false);
  const [latitude, setLatitude] = useState(0);
  const [longitude, setLongitude] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false); // Track modal state
  const modalRef = useRef(null); // Reference for the modal content

  // Function to handle visibility
  const toggleIconVisibility = (itemId) => {
    if (activeItem === itemId) {
      setActiveItem(null); // Deselect if the same item is clicked again
    } else {
      setActiveItem(itemId); // Set the active item
    }
  };

  // Fetch categories
  useEffect(() => {
    // console.log("process.env.REACT_APP_API_KEY", process.env.REACT_APP_API_KEY)
    setloader(false);
    axios
      .get(`${process.env.REACT_APP_API_KEY}get_category`)
      .then((res) => {
        if (res.data.result === "true") {
          setloader(true);
          setcategorydata(res.data.data); // Adjusted to use correct data path
        } else {
          setloader(false);
        }
      })
      .catch((error) => {
        setloader(false);
      });
  }, []);

  // googleapis keys
  useEffect(() => {
    const loadMapScript = () => {
      const script = document.createElement("script");
      script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyCGjR_PJHtFewHUhrwRiiVJFkn5_O4e7Yc&libraries=places`;
      script.async = true;
      document.body.appendChild(script);
    };

    loadMapScript(); // Load the map script on mount
  }, []); // Only run once when the component mounts
  useEffect(() => {
    if (isModalOpen) {
      // Initialize separate autocomplete instances for both inputs
      const initAutocomplete = () => {
        const map = new window.google.maps.Map(document.getElementById("map"), {
          center: { lat: latitude, lng: longitude },
          zoom: 8,
        });

        const marker1 = new window.google.maps.Marker({
          map,
          draggable: true,
          animation: window.google.maps.Animation.DROP,
        });

        const marker2 = new window.google.maps.Marker({
          map,
          draggable: true,
          animation: window.google.maps.Animation.DROP,
        });

        // First search input
        const input1 = document.getElementById("search-input-1");
        const autocomplete1 = new window.google.maps.places.Autocomplete(
          input1,
          {
            componentRestrictions: { country: "in" },
          }
        );

        autocomplete1.addListener("place_changed", () => {
          const place = autocomplete1.getPlace();
          if (!place.geometry) return;

          map.setCenter(place.geometry.location);
          marker1.setPosition(place.geometry.location);
          setLatitude(place.geometry.location.lat());
          setLongitude(place.geometry.location.lng());
        });

        // Second search input
        const input2 = document.getElementById("search-input-2");
        const autocomplete2 = new window.google.maps.places.Autocomplete(
          input2,
          {
            componentRestrictions: { country: "in" },
          }
        );

        autocomplete2.addListener("place_changed", () => {
          const place = autocomplete2.getPlace();
          if (!place.geometry) return;

          map.setCenter(place.geometry.location);
          marker2.setPosition(place.geometry.location);
          // You can save destination location here if needed
        });
      };

      initAutocomplete(); // Call autocomplete initialization
    }
  }, [isModalOpen]);

  const initMap = () => {
    const map = new window.google.maps.Map(document.getElementById("map"), {
      center: { lat: latitude, lng: longitude }, // Initially set to 0, 0 or current coordinates
      zoom: 8,
    });

    // First search input
    const input1 = document.getElementById("search-input-1");
    const autocomplete1 = new window.google.maps.places.Autocomplete(input1, {
      componentRestrictions: { country: "in" }, // Restrict to India
    });
    autocomplete1.bindTo("bounds", map);

    const marker1 = new window.google.maps.Marker({
      map,
      draggable: true,
      animation: window.google.maps.Animation.DROP,
      position: { lat: latitude, lng: longitude },
    });

    window.google.maps.event.addListener(marker1, "dragend", (event) => {
      setLatitude(event.latLng.lat());
      setLongitude(event.latLng.lng());
    });

    autocomplete1.addListener("place_changed", () => {
      const place = autocomplete1.getPlace();
      if (!place.geometry) {
        return;
      }
      if (place.geometry.viewport) {
        map.fitBounds(place.geometry.viewport);
      } else {
        map.setCenter(place.geometry.location);
        map.setZoom(17);
      }
      marker1.setPosition(place.geometry.location);
      setLatitude(place.geometry.location.lat());
      setLongitude(place.geometry.location.lng());
    });

    // Second search input
    const input2 = document.getElementById("search-input-2");
    const autocomplete2 = new window.google.maps.places.Autocomplete(input2, {
      componentRestrictions: { country: "in" }, // Restrict to India
    });
    autocomplete2.bindTo("bounds", map);

    const marker2 = new window.google.maps.Marker({
      map,
      draggable: true,
      animation: window.google.maps.Animation.DROP,
      position: { lat: latitude, lng: longitude },
    });

    window.google.maps.event.addListener(marker2, "dragend", (event) => {
      setLatitude(event.latLng.lat());
      setLongitude(event.latLng.lng());
    });

    autocomplete2.addListener("place_changed", () => {
      const place = autocomplete2.getPlace();
      if (!place.geometry) {
        return;
      }
      if (place.geometry.viewport) {
        map.fitBounds(place.geometry.viewport);
      } else {
        map.setCenter(place.geometry.location);
        map.setZoom(17);
      }
      marker2.setPosition(place.geometry.location);
      setLatitude(place.geometry.location.lat());
      setLongitude(place.geometry.location.lng());
    });
  };

  const openModal = () => {
    setIsModalOpen(true); // Open modal and trigger map initialization
  };

  const closeModal = () => {
    setIsModalOpen(false); // Close modal and cleanup
  };

  useEffect(() => {
    if (isModalOpen) {
      initMap(); // Initialize map only when modal is open
    }
  }, [isModalOpen]);

  // ambulance book msg
  const bookambulance_handel = (e) => {
    e.preventDefault();
    let form = e.target;
    let formdata = new FormData(form);
    formdata.append("ambulance_id", cate_id);
    formdata.append("lat", latitude);
    formdata.append("lng", longitude);
    setloaderbtn(true);
    axios
      .post(`${process.env.REACT_APP_API_KEY}ambulance_booking`, formdata)
      .then((res) => {
        if (res.data.result == "true") {
          setloaderbtn(false);
          // toast.success(res.data.msg)
          swal({
            title: "Success!",
            text: "Ambulance Booked Successfully",
            icon: "success",
            button: "OK",
          }).then(() => {
            form.reset(); // Reset the form
            setVisible(false); // Hide the modal or form
            setActiveItem(null);
            setIsModalOpen(false); // Close modal and cleanup
          });
        } else {
          toast.error("Something Went Wrong");
          setloaderbtn(false);
        }
      })
      .catch((err) => {
        toast.error("Something Went Wrong");
        setloaderbtn(true);
      });
  };

  return (
    <>
      <Toaster />
      <div className="sec-title text-center mt-5">
        <div className="icon">
          <span className="icon-heartbeat" />
        </div>
        <h3 className="">Select Category</h3>
      </div>

      <section className="slogan-area mb-2" style={{ background: "white" }}>
        <div
          className="map-box wow slideInRight"
          data-wow-delay="1400ms"
          data-wow-duration="5500ms">
          <img className="float-bob" src="assets/images/shape/map.png" alt />
        </div>
        <div className="container">
          <div className="single-sidebar-box px-0" style={{ padding: "0px" }}>
            <div className="popular-tag-box">
              {loader == true ? (
                <ul className="popular-tag row justify-content-center">
                  {categorydata?.map((items, index) => {
                    {
                      /* onClick={get_category_id=()=>{return items.id}} */
                    }
                    return (
                      <li
                        key={index}
                        onClick={() => (setcate_id(items.id), openModal())}
                        className="px-0 col-5 wow animate__animated animate__fadeIn "
                        style={{ width: "185px" }}>
                        <a
                          onClick={() => toggleIconVisibility(index)}
                          className="text-center"
                          style={{
                            color: "white",
                            background: "var(--thm-base)",
                            height: "130px",
                            display: "flex",
                            alignItems: "center",
                          }}>
                          <label
                            htmlFor="checkbox"
                            style={{ cursor: "pointer" }}>
                            <div className="single-features-style1">
                              <div
                                className="icon-holder text-center"
                                style={{
                                  height: "50px",
                                  width: "100%",
                                  position: "relative",
                                }}>
                                <img
                                  width={"35%"}
                                  src={`${process.env.REACT_APP_IMAGE}${items?.image}`}
                                  alt=""
                                />
                                {/* {activeItem === index && (
                                                                    <ImCheckmark
                                                                        style={{ fontSize: '30px', position: 'absolute', right: '0px', color: "white" }}
                                                                    />
                                                                )} */}
                              </div>
                            </div>
                            <p>
                              {/* {items?.service_type.slice(0,18)} */}
                              {items?.service_type}
                            </p>
                          </label>
                        </a>
                      </li>
                    );
                  })}
                </ul>
              ) : (
                <ul className="popular-tag row justify-content-center ml-1">
                  <li
                    className="px-0 col-5 wow animate__animated animate__fadeIn "
                    style={{ width: "185px" }}>
                    <Skeleton width="11rem" height="6rem"></Skeleton>
                  </li>
                  <li
                    className="px-0 col-5 wow animate__animated animate__fadeIn "
                    style={{ width: "185px" }}>
                    <Skeleton width="11rem" height="6rem"></Skeleton>
                  </li>
                  <li
                    className="px-0 col-5 wow animate__animated animate__fadeIn "
                    style={{ width: "185px" }}>
                    <Skeleton width="11rem" height="6rem"></Skeleton>
                  </li>
                  <li
                    className="px-0 col-5 wow animate__animated animate__fadeIn "
                    style={{ width: "185px" }}>
                    <Skeleton width="11rem" height="6rem"></Skeleton>
                  </li>
                  <li
                    className="px-0 col-5 wow animate__animated animate__fadeIn "
                    style={{ width: "185px" }}>
                    <Skeleton width="11rem" height="6rem"></Skeleton>
                  </li>
                  <li
                    className="px-0 col-5 wow animate__animated animate__fadeIn "
                    style={{ width: "185px" }}>
                    <Skeleton width="11rem" height="6rem"></Skeleton>
                  </li>
                </ul>
              )}
            </div>
          </div>
        </div>
      </section>

      {isModalOpen && (
        <div
          className="modal fade show"
          id="myModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myModalLabel"
          aria-hidden={!isModalOpen}
          style={{
            display: "flex", // Flexbox for centering
            justifyContent: "center", // Center horizontally
            alignItems: "center", // Center vertically
            zIndex: 1040, // Ensure it's on top
            height: "100vh", // Full viewport height
            backgroundColor: "rgba(0, 0, 0, 0.5)", // Dim background
            display: "block",
            zIndex: 1040,
          }}>
          <div className="modal-dialog" style={{ marginTop: "100px" }}>
            <div className="modal-content" ref={modalRef}>
              {/* Modal Header */}
              <div className="modal-header">
                <h5 className="modal-title">Select Location</h5>
                <button type="button" className="close" onClick={closeModal}>
                  ×
                </button>
              </div>
              {/* Modal Body */}
              <div className="modal-body">
                <div className="filter-search-form relative filter-border">
                  <form onSubmit={bookambulance_handel} className="p-2">
                    <div className="row justify-content-center mt-3 pl-3 pr-3">
                      {/* First Search Input */}
                      <div className="col-xl-3 col-lg-3 pb-2 px-1">
                        <div>
                          <IconField iconPosition="right">
                            <InputIcon
                              className="pi pi-map-marker"
                              style={{ top: "15px" }}
                            />
                            <InputText
                              id="search-input-1"
                              required
                              className="px-3 py-2"
                              placeholder="Location..."
                              name="origin"
                              style={{
                                width: "100%",
                                fontSize: "16px",
                                border: "1px solid gray",
                              }}
                            />
                          </IconField>
                        </div>
                      </div>

                      {/* Second Search Input */}
                      <div className="col-xl-3 col-lg-3 pb-2 px-1">
                        <div>
                          <IconField iconPosition="right">
                            <InputIcon
                              className="pi pi-map-marker"
                              style={{ top: "15px" }}
                            />
                            <InputText
                              id="search-input-2"
                              required
                              placeholder="To..."
                              name="destination"
                              className="px-3 py-2"
                              style={{
                                width: "100%",
                                fontSize: "16px",
                                border: "1px solid gray",
                              }}
                            />
                          </IconField>
                        </div>
                      </div>

                      <div className="col-xl-3 col-lg-3 pb-2 px-1">
                        <div>
                          <IconField iconPosition="right">
                            <InputIcon
                              className="pi pi-phone"
                              style={{ top: "15px" }}></InputIcon>
                            <InputText
                              pattern="\d{10}" // Ensures exactly 10 digits
                              title="Please enter a valid 10-digit phone number"
                              name="mobile"
                              className="px-3 py-2"
                              required
                              placeholder="Phone Number"
                              style={{
                                width: "100%",
                                fontSize: "16px",
                                border: "1px solid gray",
                              }}
                            />
                          </IconField>
                        </div>
                      </div>

                      <div className="col-xl-3 col-lg-3 pb-2 px-1">
                        <div>
                          <IconField iconPosition="right">
                            <InputIcon
                              className="pi pi-user"
                              style={{ top: "15px" }}></InputIcon>
                            <InputText
                              className="px-3 py-2"
                              name="name"
                              required
                              placeholder="Name"
                              style={{
                                width: "100%",
                                fontSize: "16px",
                                border: "1px solid gray",
                              }}
                            />
                          </IconField>
                        </div>
                      </div>
                    </div>

                    <div className="mt-4">
                      <div className="text-center">
                        <div class="slogan-btn-box">
                          {loaderbtn == true ? (
                            <div
                              class="spinner-border text-danger"
                              role="status"
                              style={{ width: "3rem", height: "3rem" }}>
                              <span class="sr-only"></span>
                            </div>
                          ) : (
                            <a class="btn-one">
                              <button
                                type="submit"
                                class="txt"
                                style={{ color: "white" }}>
                                Book Ambulance
                              </button>
                            </a>
                          )}
                        </div>
                      </div>
                    </div>
                  </form>

                  {/* Map */}
                  <div
                    id="map"
                    style={{
                      // width: '100%',
                      // height: '500px',
                      display: isModalOpen ? "none" : "none",
                      zIndex: 1050, // Inline z-index for map container
                    }}></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <style>{`
                .pac-container {
                    z-index: 1100 !important;
                }
            `}</style>
    </>
  );
};

export default Category;
