import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Navbar from "./Components/Pages/Navbar";
import Home from "./Components/Home";
import Hello from "./Components/Hello";
import Services from "./Components/Pages/Services";
import Footer from "./Components/Pages/Footer";
import About_us from "./Components/Pages/About_us";
import Error from "./Components/Pages/Error";
import Contact_us from "./Components/Pages/Contact_us";
import Privacy from "./Components/Pages/Privacy";
import Terms_Conditions from "./Components/Pages/Terms_Conditions";
import Userlogin from "./Components/Pages/Userlogin";
import User_profile from "./Components/Pages/User_profile";
import Location_update from "./Components/Pages/Location_update";
import Header_navbar from "./Components/Pages/Header_navbar";
import Faq from "./Components/Pages/Faq";
import SignUp from "./Components/Pages/SignUp";

function App() {
  // inspect lock 
  useEffect(() => {
    const disableContextMenu = (event) => {
      event.preventDefault();
    };

    const disableKeyCombinations = (event) => {
      if (event.keyCode === 123) {
        event.preventDefault();
      }
      if (event.ctrlKey && event.shiftKey && event.keyCode === 73) {
        event.preventDefault();
      }
      if (event.ctrlKey && event.shiftKey && event.keyCode === 74) {
        event.preventDefault();
      }
      if (event.ctrlKey && event.keyCode === 85) {
        event.preventDefault();
      }
    };

    document.addEventListener('contextmenu', disableContextMenu);
    document.addEventListener('keydown', disableKeyCombinations);

    return () => {
      document.removeEventListener('contextmenu', disableContextMenu);
      document.removeEventListener('keydown', disableKeyCombinations);
    };
  }, []);



  return (
    <>
      <Router>
        <Navbar />
        {/* <Header_navbar /> */}
        <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route path="/userlogin" element={<Userlogin />}></Route>
          <Route path="/signup" element={<SignUp />}></Route>
          <Route path="/user_profile" element={<User_profile />}></Route>
          <Route path="/location_update" element={<Location_update />}></Route>
          <Route path="/about_us" element={<About_us />}></Route>
          <Route path="/services" element={<Services />}></Route>
          <Route path="/contact_us" element={<Contact_us />}></Route>
          <Route path="/privacy" element={<Privacy />}></Route>
          <Route path="/faq" element={<Faq/>}></Route>
          <Route path="/terms_Conditions" element={<Terms_Conditions />}></Route>
          <Route path="*" element={<Error />}></Route>

          <Route path="/hello" element={<Hello />}></Route>
        </Routes>
        <Footer />
      </Router>

    </>
  );
}

export default App;
