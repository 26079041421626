import React from 'react'
import { Link } from 'react-router-dom'

const Footer = () => {
    return (
        <>

            <footer className="footer-area">
                <div className="footer-area-shape float-bob" style={{ backgroundImage: 'url(assets/images/shape/shape-2.png)' }} />
                {/*Start Footer*/}
                <div className="footer">
                    <div className="container">
                        <div className="row text-right-rtl">
                            {/*Start single footer widget*/}
                            <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12">
                                <div className="single-footer-widget marbtm50">
                                    <div className="our-company-info">
                                        <div className="footer-logo">
                                            {/* <a href="index-2.html">
                                                <img src="assets/images/footer/footer-logo.png" alt="Awesome Logo" title />
                                            </a> */}
                                            <a href="#">
                                                <img width={"200px"} src="./logo/logo1.png" alt="Awesome Logo" title />
                                            </a>
                                        </div>

                                        <div className="footer-widget-contact-info mt-5">
                                            <ul>
                                                <li>
                                                    <div className="inner">
                                                        <div className="icon mapmarker">
                                                            <span className="icon-pin" />
                                                        </div>
                                                        <div className="text">
                                                            <p>Ambuready Headquarter

                                                                East Gota Nagar, Maligaon

                                                                Guwahati, Assam

                                                                781011, India</p>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="inner">
                                                        <div className="icon phone">
                                                            <span className="icon-email" />
                                                        </div>
                                                        <div className="text">
                                                            <p>
                                                                <a href="mailto:ambuready@gmail.com">ambuready@gmail.com</a>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="inner">
                                                        <div className="icon">
                                                            <span className="icon-telephone" />
                                                        </div>
                                                        <div className="text">
                                                            <p>
                                                                <a href="tel:+91 70020 67082">+91 70020 67082</a>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </li>

                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/*End single footer widget*/}
                            {/*Start single footer widget*/}
                            <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12">
                                <div className="single-footer-widget single-footer-widget--link-box margintop marbtm50">
                                    <div className="title">
                                        <h3>Home</h3>
                                    </div>
                                    <div className="footer-widget-links">
                                        <ul>
                                            <li><Link to="/about_us" >About Us</Link></li>
                                            <li><Link to='/services' >Our Services</Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            {/*End single footer widget*/}
                            {/*Start single footer widget*/}
                            <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12">
                                <div className="single-footer-widget margintop">
                                    <div className="title">
                                        <h3>Useful Links</h3>
                                    </div>
                                    <div className="footer-widget-links">
                                        <ul>
                                            <li><Link to="/privacy" >Privacy Policy</Link></li>
                                            <li><Link to="/terms_Conditions" >Terms and Conditions</Link></li>
                                            <li><Link to={"/faq"} >FAQ's</Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            {/*End single footer widget*/}
                            {/*Start single footer widget*/}
                            <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12">
                                <div className="single-footer-widget margintop pdtop20">
                                    <div className="footer-widget-quote-box">
                                        <iframe
                                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d31057969.793022104!2d68.17664540426447!3d22.593726160243206!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce54c191260f9%3A0xdbc0c4f59558a6e2!2sIndia!5e0!3m2!1sen!2sus!4v1695818141677!5m2!1sen!2sus"
                                            width="100%"
                                            height="100%"
                                            style={{ border: 'none' }}
                                            allowFullScreen=""
                                            loading="lazy"
                                        />
                                    </div>
                                </div>
                            </div>

                            {/*End single footer widget*/}
                        </div>
                    </div>
                </div>
                {/*End Footer*/}
                <div className="footer-bottom">
                    <div className="container">
                        <div className="bottom-inner">
                            <div className="copyright">

                                <p>Copyright ©2024 .<a href="#">Ambuready</a> All Rights Reserved - Designed by <a href="https://www.logicalsofttech.com/">Logicalsofttech</a></p>
                            </div>
                            <div className="footer-social-link">
                                <ul className="clearfix">
                                    <li>
                                        <a href="#"><i className="icon-twitter" /></a>
                                    </li>
                                    <li>
                                        <a href="https://www.facebook.com/people/AmbuReady/61550938978127/?mibextid=LQQJ4d"><i className="icon-facebook" /></a>
                                    </li>
                                    <li>
                                        <a href="#"><i className="icon-pinterest" /></a>
                                    </li>
                                    <li>
                                        <a href="#"><i className="icon-instagram" /></a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>

        </>
    )
}

export default Footer