import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
const About_us = () => {
  const [about, setAbout] = useState([]);
  console.log(about);
  // Fetch categories
  useEffect(() => {
    //    setloader(false);
    axios
      .get(`${process.env.REACT_APP_API_KEY}about_us`)
      .then((res) => {
        if (res.data.result === "true") {
          //  setloader(true);
          setAbout(res.data.data); // Adjusted to use correct data path
        } else {
          // setloader(false);
        }
      })
      .catch((error) => {
        // setloader(false);
        console.log(error);
      });
  }, []);

  return (
    <>
    {
        about.map((item,idx)=>(
    
      <div className="boxed_wrapper ltr">
        <section className="breadcrumb-area">
          <div
            className="breadcrumb-area-bg"
            style={{
              backgroundImage:
                "url(catelogo/ambulance-rescue-emergency-blue-light.jpg)",
            }}></div>
          <div className="container">
            <div className="row">
              <div className="col-xl-12">
                <div className="inner-content">
                  <h2 className="text-white">About Us</h2>
                  <div className="breadcrumb-menu">
                    <ul>
                      <li>
                        <Link to="/">Home</Link>
                      </li>
                      <li className="active">About Us</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="pt-5">
          <div className="container">
            <div className="row text-right-rtl">
              <div className="col-xl-6">
                <div className="about-style3__image">
                  <div className="inner">
                    <img src="./catelogo/ambulace-img.png" alt />
                  </div>
                </div>
              </div>
              <div className="col-xl-6">
                <div className="about-style3__content">
                  <div className="sec-title">
                    <div className="icon" style={{display:"flex",gap:"5px",alignItems:"center"}}>
                    <span className="icon-heartbeat" />
                    <h3 dangerouslySetInnerHTML={{ __html: item?.title }} />
                    </div>
                    <div className="sub-title">
                      <h3 dangerouslySetInnerHTML={{ __html: item?.description }} style={{ textAlign: "justify" }}>
                    
                      </h3>
                    </div>
                    <h5>
                      We are available for calls 24/7, ensuring that <br /> you
                      can reach us anytime at 7002067082.
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="mt-5">
          <div className="auto-container">
            <div className="sec-title text-center">
              <div className="icon">
                <span className="icon-heartbeat" />
              </div>
              <h4>What we need to do ?</h4>
            </div>

            <div className="">
              <div className="about-style3__content">
                <div
                  className="sec-title"
                  style={{ paddingBottom: "15px", textAlign: "justify" }}>
                  <div className="sub-title">
                    <h3>
                      In emergency medical situations, it is crucial to remain
                      calm and act quickly. Panicking can hinder your ability to
                      think clearly and respond effectively. The first step is
                      to call for professional medical assistance immediately.
                      AMBUREADY provides 24/7 emergency ambulance services to
                      ensure that you receive prompt and reliable medical
                      support when you need it the most. While waiting for the
                      ambulance to arrive, keep the patient as comfortable as
                      possible, do not give them anything to eat or drink, and
                      follow any instructions provided by emergency operators.
                      Remember, timely medical intervention can save lives.
                      Trust AMBUREADY to be there for you in critical moments,
                      ensuring swift and professional care.
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="mt-5">
          <div className="auto-container">
            <div className="sec-title text-center">
              <div className="icon">
                <span className="icon-heartbeat" />
              </div>
              <h4>Integrated Medical Assistance Solutions</h4>
            </div>
            <div className="row">
              <div className="col-xl-12">
                <div className="features-style1__content">
                  <ul>
                    <li>
                      <div className="single-features-style1">
                        <div className="icon-holder">
                          <div className="box" />
                          <span className="icon-clinic" />
                        </div>
                        <div className="text-holder">
                          <h3>
                            <a href="#">24/7 Emergency Response</a>
                          </h3>
                          <p style={{ textAlign: "justify" }}>
                            We ensure a rapid and reliable emergency response
                            service to address critical situations at any time
                            of the day or night .
                          </p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="single-features-style1">
                        <div className="icon-holder">
                          <div className="box" />
                          <span className="icon-diploma" />
                        </div>
                        <div className="text-holder">
                          <h3>
                            <a href="#">
                              Comprehensive Medical Transportation Services
                            </a>
                          </h3>
                          <p style={{ textAlign: "justify" }}>
                            Our well-equipped ambulance fleet serves the entire
                            Guwahati area, ensuring the safe and efficient
                            transfer of patients to medical facilities.
                          </p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="single-features-style1">
                        <div className="icon-holder">
                          <div className="box" />
                          <span className="icon-ambulance-1" />
                        </div>
                        <div className="text-holder">
                          <h3>
                            <a href="#">Versatile Medical Support Services</a>
                          </h3>
                          <p style={{ textAlign: "justify" }}>
                            Committed to reliability and compassion, we strive
                            to be your trusted partner for a range of medical
                            services in Guwahati, Assam.
                          </p>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <section className="features-style2-area gray-bg bgimg">
                    <div className="container bgimg">
                        <div className="row">
                            <div className="col-xl-12 bgimg">
                                <div className="features-style2__title">
                                    <h2 className='text-white text-center' style={{ width: "100%", paddingTop: "100px" }}>REACH OUT NOW TO SECURE YOUR AMBULANCE BOOKING</h2>
                                    <h3 className='text-white text-center' style={{ width: "100%", paddingTop: "10px" }}><a className='text-white' href="tel:+91 70020 67082">CALL US: +91 70020 67082 </a></h3>

                                </div>
                            </div>
                        </div>
                    </div>
                </section> */}
      </div>
      ))
    }
    </>
  );
};

export default About_us;
